import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/views/LoginView"
import Home from "@/views/HomeView"

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        redirect: "/home"
    },
    {
        path: '/home',
        name: 'home',
        component: Home,
        children: [
            {
                path: '/map',
                name: 'map',
                component: () => import("@/views/MapView")
            },
            {
                path: '/public',
                name: 'public',
                component: () => import("@/views/PublicView")
            },
            {
                path: '/boatlist',
                name: "boatlist",
                component: () => import("@/views/BoatManage/BoatList")
            }, {
                path: '/addboat',
                name: "addboat",
                component: () => import("@/views/BoatManage/AddBoat")
            }, {
                path: '/editboat',
                name: "editboat",
                component: () => import("@/views/BoatManage/EditBoat")
            }, {
                path: '/triggerlist',
                name: "triggerlist",
                component: () => import("@/views/TriggerManage/TriggerList")
            }, {
                path: '/addtrigger',
                name: "addtrigger",
                component: () => import("@/views/TriggerManage/AddTrigger")
            }, {
                path: '/edittrigger',
                name: "edittrigger",
                component: () => import("@/views/TriggerManage/EditTrigger")
            }, {
                path: '/explainlist',
                name: "explainlist",
                component: () => import("@/views/ExplainManage/ExplainList")
            }, {
                path: '/addexplain',
                name: "addexplain",
                component: () => import("@/views/ExplainManage/AddExplain")
            }, {
                path: '/editexplain',
                name: "editexplain",
                component: () => import("@/views/ExplainManage/EditExplain")
            }, {
                path: '/musiclist',
                name: "musiclist",
                component: () => import("@/views/MusicManage/MusicList")
            }, {
                path: '/addmusic',
                name: "addmusic",
                component: () => import("@/views/MusicManage/AddMusic")
            }, {
                path: '/editmusic',
                name: "editmusic",
                component: () => import("@/views/MusicManage/EditMusic")
            }, {
                path: '/sceniclist',
                name: "sceniclist",
                component: () => import("@/views/ScenicManage/ScenicList")
            }, {
                path: '/addscenic',
                name: "addscenic",
                component: () => import("@/views/ScenicManage/AddScenic")
            }, {
                path: '/editscenic',
                name: "editscenic",
                component: () => import("@/views/ScenicManage/EditScenic")
            }, {
                path: '/picturelist',
                name: "picturelist",
                component: () => import("@/views/PictureManage/PictureList")
            }, {
                path: '/addpicture',
                name: "addpicture",
                component: () => import("@/views/PictureManage/AddPicture")
            }, {
                path: '/editpicture',
                name: "editpicture",
                component: () => import("@/views/PictureManage/EditPicture")
            }, {
                path: '/rewardlist',
                name: "rewardlist",
                component: () => import("@/views/RewardManage/RewardList")
            }, {
                path: '/addreward',
                name: "addreward",
                component: () => import("@/views/RewardManage/AddReward")
            }, {
                path: '/editreward',
                name: "editreward",
                component: () => import("@/views/RewardManage/EditReward")
            }, {
                path: '/rmblist',
                name: "rmblist",
                component: () => import("@/views/RMBManage/RMBList")
            }, {
                path: '/addrmb',
                name: "addrmb",
                component: () => import("@/views/RMBManage/AddRMB")
            }, {
                path: '/editrmb',
                name: "editrmb",
                component: () => import("@/views/RMBManage/EditRMB")
            }, {
                path: '/taellist',
                name: "taellist",
                component: () => import("@/views/TaelManage/TaelList")
            }, {
                path: '/addtael',
                name: "addtael",
                component: () => import("@/views/TaelManage/AddTael")
            }, {
                path: '/edittael',
                name: "edittael",
                component: () => import("@/views/TaelManage/EditTael")
            }, {
                path: '/routelist',
                name: "routelist",
                component: () => import("@/views/RouteManage/RouteList")
            }, {
                path: '/addroute',
                name: "addroute",
                component: () => import("@/views/RouteManage/AddRoute")
            }, {
                path: '/editroute',
                name: "editroute",
                component: () => import("@/views/RouteManage/EditRoute")
            }, {
                path: '/orderlist',
                name: "orderlist",
                component: () => import("@/views/OrderManage/OrderList")
            }, {
                path: '/adminlist',
                name: "adminlist",
                component: () => import("@/views/AdminManage/AdminList")
            }, {
                path: '/addadmin',
                name: "addadmin",
                component: () => import("@/views/AdminManage/AddAdmin")
            }, {
                path: '/editadmin',
                name: "editadmin",
                component: () => import("@/views/AdminManage/EditAdmin")
            }, {
                path: '/userlist',
                name: "userlist",
                component: () => import("@/views/UserManage/UserList")
            }, {
                path: '/adduser',
                name: "adduser",
                component: () => import("@/views/UserManage/AddUser")
            }, {
                path: '/edituser',
                name: "edituser",
                component: () => import("@/views/UserManage/EditUser")
            }, {
                path: '/scriptlist',
                name: "scriptlist",
                component: () => import("@/views/ScriptManage/ScriptList")
            }, {
                path: '/addscript',
                name: "addscript",
                component: () => import("@/views/ScriptManage/AddScript")
            }, {
                path: '/editscript',
                name: "editscript",
                component: () => import("@/views/ScriptManage/EditScript")
            },
            {
                path: '/nicknamelist',
                name: "nicknamelist",
                component: () => import("@/views/NicknameManage/NicknameList")
            }, {
                path: '/addnickname',
                name: "addnickname",
                component: () => import("@/views/NicknameManage/AddNickname")
            }, {
                path: '/editnickname',
                name: "editnickname",
                component: () => import("@/views/NicknameManage/EditNickname")
            },
            {
                path: '/giftlist',
                name: "giftlist",
                component: () => import("@/views/GiftManage/GiftList")
            }, {
                path: '/addgift',
                name: "addgift",
                component: () => import("@/views/GiftManage/AddGift")
            }, {
                path: '/editgift',
                name: "editgift",
                component: () => import("@/views/GiftManage/EditGift")
            },{
                path: '/blessinglist',
                name: "blessinglist",
                component: () => import("@/views/BlessingManage/BlessingList")
            }, {
                path: '/addblessing',
                name: "addblessing",
                component: () => import("@/views/BlessingManage/AddBlessing")
            }, {
                path: '/editblessing',
                name: "editblessing",
                component: () => import("@/views/BlessingManage/EditBlessing")
            },{
                path: '/mapconfiglist',
                name: "mapconfiglist",
                component: () => import("@/views/MapConfig/MapConfigList")
            }, {
                path: '/addmapconfig',
                name: "addmapconfig",
                component: () => import("@/views/MapConfig/AddMapConfig")
            }, {
                path: '/editmapconfig',
                name: "editmapconfig",
                component: () => import("@/views/MapConfig/EditMapConfig")
            },
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
]

const router = new VueRouter({
    mode: 'hash', // 使用 hash 模式
    routes
});

router.beforeEach((to, from, next) => {
    const token = localStorage.getItem("hc-token")
    if (to.name === "login") {
            next()
    } else {
        if (!token) {
            next({
                path: "/login"
            })
        } else {
            next()
        }
    }
})

export default router;