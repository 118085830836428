<template>
    <div class="main">
        <div class="cover-img">
            <img src="../../public/img/壁纸.jpg" fit="cover">
            <!-- <el-image :src="src" fit="cover"></el-image> -->
        </div>
        <div class="login-form">
            <div class="content">
                <h1>秦淮河游船数字化运营管理系统</h1>
                <el-form :model="loginForm" status-icon :rules="rules" ref="ruleForm" label-width="100px"
                    class="demo-ruleForm">
                    <el-form-item label="用户名：" prop="username">
                        <el-input placeholder="请输入" v-model="loginForm.username" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="密码：" prop="password">
                        <el-input type="password" placeholder="请输入" v-model="loginForm.password" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="login">登录</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loginForm: {
                username: "",
                password: ""
            },
            rules:{}
        }
    },
    methods: {
        login() {
            this.$axios.post("/login", this.loginForm).then(res => {
                // console.log(res);
                if (res.code == 0) {
                    const { token } = res.data
                    localStorage.setItem("hc-token", token)
                    this.$router.replace("/home")
                }
            })
        }
    },
    mounted() {
        
    }
}

</script>

<style scoped lang="scss">
.main {
    display: flex;

    .cover-img {
        width: 50%;
        height: 100vh;

        img {
            width: 100%;
            height: 100vh;
            vertical-align: middle;
        }
    }

    .login-form {
        width: 50%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;

        .content {
            width: 600px;
            height: 500px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: rgb(252, 202, 0);

            h1 {
                margin-bottom: 30px;
                color: #999999;
            }

            .el-form {
                width: 400px;
            }

        }

    }

}
</style>